import React, { Component } from 'react';
import { Row, Form, Icon, Col } from 'antd';

class App extends Component {

    render() {
        return (
            <div style={{ fontSize: '10px' }}>
                <Row>
                    <Row style={{ color: '#03a9f4' }}>
                        <Col xs={1}>
                            <Icon type="exclamation-circle" />
                        </Col>
                        <Col xs={22} style={{ fontSize: '13px', marginTop: -2, marginLeft: 2, }}>Broadcast Message</Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>Please be informed about AMALA System Maintenance activity plan which will be scheduled on</Row>
                    <Row style={{ marginTop: '5px' }}>
                        <Col xs={4}>Start Time</Col>
                        <Col xs={1}>:</Col>
                        <Col xs={19}>
                            <strong>Thursday, December 12, 2024 at 22:00 (UTC+ 07:00)</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>End Time</Col>
                        <Col xs={1}>:</Col>
                        <Col xs={19}>
                            <strong>Friday, December 13, 2024 at 00:30 (UTC+ 07:00)</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>Downtime</Col>
                        <Col xs={1}>:</Col>
                        <Col xs={19}>
                            <strong>150 minutes</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>Impact</Col>
                        <Col xs={1}>:</Col>
                        <Col xs={19}>
                            <strong>Unable to redeem AIr / Non Air</strong>
                        </Col>
                    </Row>
                </Row>
            </div>
        )
    }
}

export default Form.create()(App);